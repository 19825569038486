<template>
  <div
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="content">
      <router-link :to="{ path: './program' }">
        <el-button style="padding: 6px 32px">返回</el-button></router-link
      >
      <el-table :data="tableData" style="width: 100%; margin-top: 1.5%">
        <el-table-column prop="id" label="ID" width="100"> </el-table-column>
        <el-table-column
          prop="name"
          label="分类名称"
          width="150"
        ></el-table-column>
        <el-table-column prop="createDate" label="添加时间" width="160">
        </el-table-column>
        <el-table-column prop="updateDate" label="修改时间" width="160">
        </el-table-column>
        <el-table-column prop="system" label="系统操作" width="160">
          <template slot-scope="scope">
            <img
              class="buts"
              src="../../assets/images/for.png"
              @click="editClass(scope.$index, scope.row)"
              alt
            />
            <img
              class="buts bu"
              src="../../assets/images/del.png"
              @click="deClass(scope.$index, scope.row)"
              alt
            />
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom-left" style="margin-top: 2%">
        <!-- <p class="bottom-shu">共计{{ totalCount }}条数据</p> -->
        <el-button
          style="
            margin-left: 25px;
            font-size: 16px;
            color: #606266;
            border-radius: 25px;
            background: #d78673;
            color: #ffffff;
          "
          @click="addClass"
          >添加</el-button
        >
      </div>
      <!-- 
      <div class="pages" style="float: right">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 15, 20, 25, 30]"
          :page-size="pageSize"
          :current-page="pageCount"
          layout="total, prev, pager, next,jumper,sizes"
          :total="totalCount"
          @pagination="getData"
        >
        </el-pagination>
      </div> -->
    </div>
    <!-- 增加按钮的弹框 -->
    <el-dialog
      title="添加"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <el-form
        :model="formInline"
        class="demo-form-inline"
        style="margin-left: 20px"
      >
        <el-form-item label="分类名称">
          <el-input
            v-model="formInline.name"
            placeholder=""
            style="width: 50%"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addList">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="添加"
      :visible.sync="dialogVisible1"
      width="40%"
      :before-close="handleClose1"
    >
      <el-form
        :model="formInline"
        class="demo-form-inline"
        style="margin-left: 20px"
      >
        <el-form-item label="分类名称">
          <el-input
            v-model="formInline.name"
            placeholder=""
            style="width: 50%"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="upList">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      currentPage1: 1,
      pageSize: 10,
      totalCount: 0,
      pageCount: 1,
      tableData: [],
      dialogVisible: false,
      dialogVisible1: false,
      formInline: {},
      upId: null,
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    addList() {
      apiShout
        .inType({
          name: this.formInline.name,
        })
        .then((res) => {
          console.log("添加：", res.data);
          if (res.data.code == 200) {
            this.dialogVisible = false;
            this.$message({
              message: "成功添加",
              type: "success",
            });
            this.getData();
            this.formInline = {};
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    upList() {
      apiShout
        .upType({
          id: this.upId,
          name: this.formInline.name,
        })
        .then((res) => {
          console.log("添加：", res.data);
          if (res.data.code == 200) {
            this.dialogVisible1 = false;
            this.$message({
              message: "成功修改",
              type: "success",
            });
            this.getData();
            this.formInline = {};
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleClose1() {
      this.dialogVisible1 = false;
    },
    //列表查询
    getData() {
      apiShout.getType({}).then((res) => {
        if (res.data.code == 200) {
          console.log(res, "查询列表返回数据");
          this.tableData = res.data.result;
          // this.totalCount = res.data.result.page.totalCount;
          // this.pageSize = res.data.result.page.pageSize;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    addClass() {
      this.dialogVisible = true;
      this.formInline = {};
    },
    editClass(index, row) {
      this.upId = row.id;
      this.dialogVisible1 = true;
      this.formInline={...row};
    },
    deClass(index, row) {
      this.$confirm("是否确定删除此信息?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiShout.deType({ id: row.id }).then((res) => {
            // console.log("删除：", res.data);
            if (res.data.code == 200) {
              this.$message({
                message: "成功删除",
                type: "success",
              });
              this.getData();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      console.log(this.pageSize, "padesize大小");
      this.getData();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      this.pageCount = pageCount;
      console.log(this.pageCount);
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding: 40px 30px 0px;
}
/deep/ .el-table tbody tr:hover > td {
  background-color: #f5f7fa;
}
/deep/.el-table td,
.el-table th {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
/deep/.el-form-item__label {
  color: #909399;
}
.buts {
  width: 36px;
  height: 27px;
}
</style>